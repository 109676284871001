import React from 'react'
import LegalMenu from '../components/legalMenu'
import Meta from '../components/layout/meta'
import { Layout } from '../components/layout'
import { LegalContent, StickyLegalMenu, TextContainer } from '../utils'

const DisclaimerPage = () => (
    <Layout>
        <Meta title="Tellow - Disclaimer" description="Tellow's disclaimer" path="/disclaimer" />
        <LegalContent>
            <StickyLegalMenu>
                <LegalMenu className="sticky" />
            </StickyLegalMenu>
            <TextContainer>
                <h1>Disclaimer</h1>
                <div className="col-lg-8 padding-top padding-bottom">
                    <h2>Wie zijn wij?</h2>
                    <p>
                        Wij zijn Tellow. De Tellow B.V. is onderdeel van de{' '}
                        <a href="https://nl.ageras.com/" target="_blank" rel="noopener noreferrer">
                            Ageras Group
                        </a>
                        . Wij zijn eigenaar van de website www.tellow.nl (hierna genoemd: ‘website’) en van de mobiele app Tellow (hierna genoemd: ‘app’).
                    </p>
                    <p>
                        Informatie die wordt getoond in de app en de website (zoals bonnetjes, facturen en boekhouding) staat in een aparte database. Persoonlijk identificeerbare
                        informatie kan en zal niet met partners worden gedeeld. Medewerkers van onze partners hebben nooit toegang tot een Tellow account.
                    </p>
                    <h2>Informatie</h2>
                    <p>
                        De informatie is afkomstig van bronnen die betrouwbaar mogen worden geacht. Hoewel de informatie zorgvuldig is samengesteld, staan wij niet in voor de
                        juistheid, volledigheid en actualiteit ervan. Je bent zelf verantwoordelijk voor de keuze en het gebruik van de informatie. De overeenkomst, de algemene
                        voorwaarden en andere informatie zijn in het Nederlands.
                    </p>
                    <h2>Voor wie is Tellow bedoeld?</h2>
                    <p>De diensten en informatie van Tellow zijn bedoeld voor eenmanszaken die zijn gevestigd en belastingplichtig in Nederland, met name voor zzp’ers.</p>
                    <h2>Informatie op sites waar naar gelinkt wordt en cookies</h2>
                    <p>
                        Op de website wordt soms gelinkt naar externe sites. De informatie op deze externe sites is door ons niet gecontroleerd op juistheid. Wij zijn niet
                        verantwoordelijk voor die informatie. Let er op dat als je via de website of app op een website van een derde komt, er cookies kunnen worden geïnstalleerd
                        via die website. Wij zijn hiervoor niet verantwoordelijk en aansprakelijk.
                    </p>
                    <h2>Intellectueel eigendom van Tellow</h2>
                    <p>
                        Wij behouden alle intellectuele eigendomsrechten op de gegevens en materialen (zoals teksten, beelden en databestanden) die op de website en in de app
                        worden gebruikt. Kopiëren, verspreiden en elk ander gebruik van deze materialen is niet toegestaan zonder schriftelijke toestemming van ons, behoudens en
                        slechts voor zover anders bepaald in regelingen van dwingend recht (zoals citaatrecht). Je mag de app alleen gebruiken voor het doel waarvoor deze bestemd
                        is. Je mag de app in elk geval niet kopiëren, wijzigen, onderzoeken of aan derden ter beschikking stellen.
                    </p>
                    <h2>Over de disclaimer</h2>
                    <p>Wij mogen deze disclaimer aanpassen. De meest actuele disclaimer vind je altijd op de website en in de app.</p>
                    <h2>Contact via e-mail</h2>
                    <p>
                        Voor vragen of klachten kun je mailen naar&nbsp;
                        <a href="mailto:support@tellow.nl">support@tellow.nl</a>.
                    </p>
                </div>
            </TextContainer>
        </LegalContent>
    </Layout>
)

export default DisclaimerPage

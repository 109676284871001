import React from 'react'
import styled from 'styled-components'
import { Link } from './UI'

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.color.tellowBlue};

    &.active {
        font-weight: bold;
        color: currentColor;
    }
`

const LegalMenu = ({ className }) => (
    <ul className={className}>
        <li>
            <StyledLink to="/cookies/" activeClassName="active">
                Cookies
            </StyledLink>
        </li>
        <li>
            <StyledLink to="/privacy/" activeClassName="active">
                Privacyverklaring
            </StyledLink>
        </li>
        <li>
            <StyledLink to="/voorwaarden/" activeClassName="active">
                Voorwaarden
            </StyledLink>
        </li>
        <li>
            <StyledLink to="/disclaimer/" activeClassName="active">
                Disclaimer
            </StyledLink>
        </li>
    </ul>
)

export default LegalMenu
